
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'BN 3rd Place';
  src: local('BN 3rd Place'),
       url("/static/media/BN 3rd Place.49d9f20d.ttf") format('ttf'),
       url(/static/media/BN-3rd-Place.ttf.d18d17b6.woff) format('woff'),
       url(/static/media/BN-3rd-Place.ttf.f975c6f7.eot) format('eot'),
       url(/static/media/BN-3rd-Place.ttf.4d066482.svg) format('svg');
}

.App {
  text-align: center;
  font-family: 'Raleway', 'Montserrat';
  overflow: hidden;
}

/* navbar */

.n {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: right;
}
.navv {
  background-color: none;
  z-index: 3;
  
}
.nbg {
  background-color: #2b2b2b5b;
  width: 15%;
  margin-right: 5%;
  padding-right: 1%;
}

.navbar-toggler {
  margin-right: 5%; 
}
.navbar-toggler:focus {
  outline: none !important;
}
.fa-bars {
  color: white;
  border: none;
  font-size: 3.5vh;
  height: auto;
}
.navbar-dark .navbar-toggler {
  border: 0;
}

@media (max-width: 800px){
  .nbg {
    width: auto;
    margin-right: 5%;
    padding-right: 5%;
    padding-top: 2%;
    background-color: #2b2b2b;
    margin-top: 10vh;
  }
  .navbar-toggler {
    margin-right: 5%;
  }
  .fa-bars {
    font-size: 4.5vh;
  }
  
}
.l {
  font-weight: bolder;
  font-size: 20px !important;
  font-family: 'Montserrat';
}
.left-box {
  width: 40%;
  display: flexbox;
  text-align: left;
  margin-left: 3%;
}
.left {
  display: inline-flex;
  margin: auto;
  color: transparent;
  margin-left: 3%;
  margin-right: 3%;
  font-weight: 900;
  font-size: 1px;
  font-family: 'Permanent Marker', sans-serif;
  text-decoration: none;
}
@media only screen and (max-width: 900px){
  .left {
    color: rgba(255, 255, 255, 0);
    font-size: 1px;
  }
}
.left:hover {
  color: white;
  text-decoration: none;
}
.title {
  position: absolute !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 7% !important;
  font-size: 23px !important;
  text-decoration: none !important;
  color: transparent;
  background-image: url(/static/media/kenlogo-long-wht.c41f350e.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 90px;
  width: 600px;
  text-decoration: none;
  margin-top: .75%;
}
.faketitle {
  text-decoration: none;
  color: transparent;
  font-size: 60px;
  letter-spacing: 35px;
}
.title :hover {
  color: transparent;
  text-decoration: none;
}
#mobilelogo {
  display: none;
}
@media (max-width: 800px) {
  .title {
    position: absolute;
    width: 50%;
    height: 14vh;
    top: 20% !important;
    background-color: none;
    margin-top: 0;
    display: none !important;
  }
  .faketitle {
    font-size: 15px;
  }
  #mobilelogo {
    display: block;
  }
}

  /* home page */


.video {
  size: contain;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 800px){
  #smallvid {
    display: none;
  }
}
@media (max-width: 800px){
  #bigvid {
    display: none;
  }
}
#moretext {
  font-family: 'BN 3rd Place';
  font-weight: 800;
}

.a,.b,.c,.d,.e,.f {
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  @media only screen and (max-width: 768px) {
    background-size: cover;
  }
}

@media (max-width: 800px){
  .a {
    overflow: hidden;
  }
}
.b {
  height: 70vh;
  min-height: 70vh;
  background-color: #ffffff;
  overflow: hidden;
  margin-top: 0 !important;
  z-index: 5;
 
}
.info {
  margin: auto;
  color: #1e2427;
  font-weight: 400;
  
  padding-top: 15.75%;
  font-size: 31px;
  font-family: 'Cinzel';
  width: 60%;


}
.binfoh {
  border-color: #2b2b2b;
}
@media (max-width: 800px) {
  .info {
    font-size: 25px;
    margin: auto;
    padding: 1%;
    padding-top: 1%;
    width: 88%;
  }
  .b {
    height: auto;
    min-height: auto !important;
    vertical-align: center;
    margin-bottom: auto;
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

hr {
  width: 25%;
  color: #2b2b2b;
}
/* gallery */
.scrollsection {
  height: 10vh;
  background-image: url(/static/media/gallerybg.439c403e.jpg);
  background-size: cover;
  background-position-y: 28%;
}
.gallery {
  color: white;
  font-family: 'BN 3rd Place';
  font-style: italic;
  text-align: left;
  padding-left: 4%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  font-weight: 800;
}
.scrolling-wrapper {
  height: 66vh;
  flex-direction: row;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 2.5%;
  -webkit-overflow-scrolling: touch;
  background-image: url(/static/media/gallerybg.439c403e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 61%;
  overflow-y: hidden;
  
}

.bottomscroll {
  height: 12vh;
  background-image: url(/static/media/gallerybg.439c403e.jpg);
  background-size: cover;
  background-position-y: 71%;
  margin-top: -7%;
}
.cards {
  height: 450px;
  width: 450px;
  margin: auto;
  margin-right: 5%;
  margin-left: 5%;
  flex: 0 0 auto;
  background-color: transparent;
  background-image: url(/static/media/testcar2.93099123.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.slide1 {
  background-image: url(/static/media/slidecar1.91865c47.png);
}
.slide2 {
  background-image: url(/static/media/slidecar2.ba733143.png);
}
.slide3 {
  background-image: url(/static/media/slidecar3.395d5695.png);
}
.slide4 {
  background-image: url(/static/media/slidecar4.23b27f43.png);
}
.slide5 {
  background-image: url(/static/media/slidecar5.efad22c7.png);
}
.slide6 {
  background-image: url(/static/media/slidecar6.50caa2da.png);
}
.slide7 {
  background-image: url(/static/media/slidecar7.733d10ef.png);
}
.slide8 {
  background-image: url(/static/media/slidecar8.d95cb9f5.png);
}
.slide9 {
  background-image: url(/static/media/slidecar9.e5dd9b7e.png);
}
.slide10 {
  background-image: url(/static/media/slidecar10.e7488860.png);
}
.slide11 {
  background-image: url(/static/media/slidecar11.781f9622.png);
}
.slide12 {
  background-image: url(/static/media/slidecar12.9289895d.png);
}
.slide13 {
  background-image: url(/static/media/slidecar13.c045d2b0.png);
}
.slide1 {
  margin-left: 1%;
}
@media (max-width: 800px){
  .cards {
    height: 220px;
    width: 250px;
  }
  .scrolling-wrapper {
    padding-left: 70%;
  }
  
  .slide1 {
    margin-left: -220%;
  }
  #flag {
    margin-left: 0;
  }
  .slide1, .slide2, .slide3, .slide4, .slide5, .slide6, .slide7, .slide8, .slide9, .slide10, .slide11, .slide12, .slide13 {
    margin-right: 5%;
  }
  .gallery {
    font-size: 30px;
  }
  .bottomscroll {
    margin-top: -10%;
    height: 0;
  }
  .scrollsection {
    background-position-y: 0%;
  }
}


.c {
  background-image: url(/static/media/bluecar.ef42830c.jpg);
}
.d {
  background-image: url(/static/media/steeringwheel.1921506f.jpg);
  
}
.e {
  background-image: url(/static/media/car1-7.b0f6b57d.jpeg);
}
@media (max-width: 800px){
  .c {
    background-image: url(/static/media/bluecartall.8f4f9ee5.jpg);
    background-attachment: scroll;
  }
  .d {
    background-image: url(/static/media/yellowcar.fd2ff79a.jpg);
    background-attachment: scroll;
  }
  
  .e {
    background-image: url(/static/media/smallsteering.0ea9197d.jpg);
    background-attachment: scroll;
  }
  
}

/* front titles and design now buttons */
.mods {
  color: white;
  font-size: 9vh;
  padding-top: 10%;
  font-weight: 900;
  font-family: 'BN 3rd Place', 'Montserrat';
  font-style: italic;
}
.designyours {
  margin-bottom: 5%;
  margin-top: 20%;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-style: italic;
  color: black;
}
@media (max-width: 800px){
  .mods {
    padding-top: 50%;
    font-size: 5.5vh;
  }

}
@media only screen and (max-width: 800px){
  .designyours {
    margin-top: 18%;
  }
}
/* contact */
.contact-middle {
  color: rgb(196, 180, 147);
  font-weight: 800;
  background-color: rgb(0, 0, 0);
  height: 90vh;
  width: 50vw;
  text-align: center;
  padding-top: 5%;
}

.contact {
  color: rgb(196, 180, 147);
  font-weight: 800;
  margin: auto;
  padding-top: 1vh;
  background-color: rgb(0, 0, 0);
  text-align: left;
  padding-left: 20%;
  padding-right: 20%;
}
.emailus, #fname, #lname, #email, #phone, #explanation {
  font-weight: 800;
  color: rgb(196, 180, 147);
}
#fname, #lname, #email, #phone, #explanation {
  color: black;
  height: 7vh;
}
.contactpage {
  background-color: black;
  height: auto;
  padding-bottom: 6%;
}
.contact-left {
  width: 20vw;
  padding-top: 5.5%;
}
.allcontact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 11vh;
  
}
@media (max-width: 800px) {
  .allcontact {
    flex-direction: column;

  }
  .contact-left {
    width: 100vw;
    text-align: center;
    order: 2;
    margin-top: 25%;
    padding-bottom: 10%;
    padding-top: 0;
  }
  .contact-middle {
    width: 80vw;
    order: 1;
    padding-bottom: 5%;
    margin: auto;
  }
  .contact {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.cq {
  font-size: 22px;
}
.cinfo {
  font-size: 20px;
  color: white;
}
.hrs {
  font-size: 16px;
  color: grey;
}
/* Lower Nav */
.lower {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 20vh;
}
.bottomlogo {
  width: 10vw;
  height: 9vh;
  background-image: url(/static/media/kenlogo1-blk.b17638a5.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  margin-left: 3%;
  margin-right: 2%;
  margin-top: auto;
  margin-bottom: auto;
}

.cw {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  font-weight: 800;
  text-align: left;
  font-family: 'Cinzel';
}
.bottomright {
  width: auto;
  min-width: 45vw;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 10%;
  padding-right: 2%;
}
.bottomlinks {
  display: flex;
  font-size: 11px;
  font-weight: 800;
  list-style-type: none;
  margin: auto;
  justify-content: space-evenly;
  font-family: 'Cinzel';
}
.horizsep {
  display: none;
}
#mobileLogo {
  display: none;
}
@media (max-width: 800px){
  .bottomlogo {
    width: 25vw;
    margin-left: 5%;
    margin-bottom: 2%;
  }
  .lower {
    height: auto;
    flex-direction: column;
    padding: 5%;
  }
  .cw {
    font-size: 1.8vw;
    order: 2;
    width: 95vw;
    text-align: left;
    padding-left: 2%;
    margin-top: 3%;
    margin-bottom: 2%;
  }
  .bottomright {
    width: 60vw;
    margin: auto;
    margin-left: -8%;
    padding-left: 0;
    text-align: left;
    order: 1;
    flex-direction: column;
  }
  .bottomlinks {
    font-size: 2.3vw;
    flex-direction: column;
  }
  .vertsep {
    display: none;
  }
  .horizsep {
    display: flex;
    width: 80vw;
    margin: auto;
  }
  #desktopLogo {
    display: none;
  }
  #mobileLogo {
    display: flex;
    width: 25vw !important;
    order: 2;
    margin-left: 3%;
    margin-top: 5%;
  }
}

.fix {
  text-decoration: none;
  color: black;
}
.fix:hover {
  text-decoration: none;
  color: black;
}

/* Services */

.sa {
  background-image: url(/static/media/group7.958674cb.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 42%;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
}
.satext {
  color: white;
  font-size: 10vh;
  padding-top: 20%;
  font-weight: 400;
  font-family: 'Montserrat';
}
.saab {
  background: #2b2b2b;
  color: white;
  width: 100vw;
  min-width: 100%;
  height: auto;
  min-height: 20%;
  text-align: left;
  display: flex;
  justify-content: center;
}
.sb1 {
  width: 50vw;
  padding: 4%;
}
.sb2 {
  width: 50vw;
  background-image: url(/static/media/shawnprofile.414652af.jpg);
  background-size: cover;
  background-position-y: 25%;
}
.est {
  margin-left: 5%;
  margin-top: 3%;
  font-size: 55px;
  font-weight: 800;
  font-family: 'Montserrat';
}
.servinfo {
  width: 75%;
  font-size: 40px;
  margin-left: 5%;
  margin-top: 3%;
  font-family: 'Montserrat';
}
@media (max-width: 800px){
  .saab {
    height: auto;
    text-align: center;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .est {
    font-size: 35px;
    margin: auto;
    font-weight: 400;
    width: 70%;
    margin-top: 2%;
    margin-left: auto;
  }
  .servinfo {
    width: 90vw;
    margin: auto;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 25px;
    margin-left: auto;
  }
  .sb1 {
    width: 95%;
    order: 2;
  }
  .sb2 {
    order: 1;
    width: 100vw;
    height: 80vh;
    background-position-y: 8%;
  }
  .sa {
    height: 35vh;
    width: 90vw;
  }
}
.sb {
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  min-width: 100%;
  height: auto;
  min-height: 90%;
  padding-top: 5%;
}
.spec {
  color: #2b2b2b;
  font-size: 7vh;
  padding-top: 2%;
  text-align: left;
  padding-left: 5%;
  font-family: 'Montserrat';
}
.boxes {
  display: flex;
  width: 100vw;
  height: 40vh;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4%;
  padding-bottom: 20%;
  padding-top: 10%;
}
@media (max-width: 800px) {
  .boxes {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3%;
    padding-bottom: 10%;
    
  }
  .sb {
    height: 230vh;
  }
  .triplebox, .b2, .b3 {
    margin-top: 25%;
  }
  .b1 {
    margin-top: 5%;
  }
  .subinfo {
    color: transparent;
  }
}
.triplebox {
  height: 330px;
  width: 385px;
}
.triplebox:hover {
  width: 400px;
  height: 350px;
}
@media (max-width: 800px){
  .triplebox {
    height: 50vh;
    width: 90vw;
  }
}
.b1 {
  background-image: url(/static/media/welding.8c22e122.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: grayscale(35%);
          filter: grayscale(35%);
}
.b2 {
  background-image: url(/static/media/grinding.84541bee.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: grayscale(35%);
          filter: grayscale(35%);
}
.b3 {
  background-image: url(/static/media/engine.0d40829c.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: grayscale(35%);
          filter: grayscale(35%);
}
.boxtext {
  font-size: 25px;
  margin-top: 350px;
}
@media (max-width: 800px){
  .boxtext {
    font-size: 22px;
    margin-top: 55vh;
  }
 
}


