
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'BN 3rd Place';
  src: local('BN 3rd Place'),
       url('./fonts/BN\ 3rd\ Place.ttf') format('ttf'),
       url('./fonts/BN-3rd-Place.ttf.woff') format('woff'),
       url('./fonts/BN-3rd-Place.ttf.eot') format('eot'),
       url('./fonts/BN-3rd-Place.ttf.svg') format('svg');
}
